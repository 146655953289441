import Fuse from "fuse.js";
import React, { useMemo } from "react";
import { useMatchHistoryContext } from "../../../context/matchHistory";
import ChampionsSearch from "../../Champions/Search";
import { StyledMatchSearch } from "./style";

export type Props = {
  allPlayerNames: string[],
  onSearch: (query: string) => void
};

const MatchSearch: React.FC<Props> = ({ allPlayerNames, onSearch }: Props) => {
  const {
    setHighlightPlayerNames,
    clearHighlightPlayerNames
  } = useMatchHistoryContext();

  const fuse = useMemo(() => new Fuse(allPlayerNames, {
    threshold: 0.2,
    includeScore: true
  }), [allPlayerNames]);

  const handleSearch = (query: string) => {
    const trimmed = query.trim();
    onSearch(trimmed);
    if (trimmed.length === 0) {
      clearHighlightPlayerNames();
    } else {
      const results = fuse.search(trimmed);
      if (results.length > 0) {
        setHighlightPlayerNames(results.map((result) => result.item));
      } else {
        clearHighlightPlayerNames();
      }
    }
  };

  return (
    <StyledMatchSearch>
      <ChampionsSearch onSearch={handleSearch} />
    </StyledMatchSearch>
  );
};

export default MatchSearch;
