import React, { useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Times } from "../../../helpers/utils";
import { StyledMatchDate } from "./style";

export type Props = {
  time: number
};

const MatchDate: React.FC<Props> = ({ time }: Props) => {
  const { i18n, t } = useTranslation();

  const formatters = useMemo(
    () => ({
      weekday: new Intl.DateTimeFormat(i18n.language, {
        weekday: "long"
      }),
      dayAndWeek: new Intl.DateTimeFormat(i18n.language, {
        month: "long",
        day: "numeric"
      })
    }),
    [i18n.language]
  );

  const relativeWeekday = useMemo(
    () => getRelativeDayFromToday(t, time) || formatters.weekday.format(time),
    [time, i18n.language]
  );
  const dayAndMonth = useMemo(
    () => formatters.dayAndWeek.format(time),
    [time, i18n.language]
  );

  return (
    <StyledMatchDate>
      <span className="weekday">{ relativeWeekday }</span>
      <span className="divider">{` - `}</span>
      <span className="day-and-month">{ dayAndMonth }</span>
    </StyledMatchDate>
  );
};

export default MatchDate;

/**
 * Return the localized relative day if it is yesterday, earlier today, today or
 * tomorrow. Otherwise return empty string.
 */
const getRelativeDayFromToday = (t: TFunction, datetime: number | Date) => {
  const day = 1 * Times.Day;
  // set h, m, s, and ms to the start of the day
  const today = new Date().setHours(0, 0, 0, 0);
  const date = new Date(datetime).setHours(0, 0, 0, 0);

  if (today === date) {
    return datetime < Date.now() ? t("date.earlierToday") : t("date.today");
  }
  else if (today - day === date) {
    return t("date.yesterday");
  }
  else if (today + day === date) {
    return t("date.tomorrow");
  }

  return "";
};
