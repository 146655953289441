import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { cls } from "../../../../helpers/utils";
import { useFetch } from "../../../../hooks/use-fetch";
import dataDragon from "../../../../services/dataDragon";
import InformError from "../../../Inform/Error";
import { StyledMatchPlayerSummoner } from "./style";

export type Props = {
  gameVersion: string
  spellIconKey: number
};

const MatchPlayerSummoner: React.FC<Props> = ({
  gameVersion,
  spellIconKey
}: Props) => {
  const { i18n } = useTranslation();

  const { isLoading, response, error } = useFetch(
    () => dataDragon.getSummonerSpell(i18n.language, gameVersion, spellIconKey),
    [i18n.language, gameVersion, spellIconKey]
  );

  return (
    <StyledMatchPlayerSummoner className={cls(isLoading && "loading")}>
      {error && <InformError error={error} />}
      {response && <img src={response.imageUrl} alt={response.data.name}/>}
    </StyledMatchPlayerSummoner>
  );
};

export default MatchPlayerSummoner;
