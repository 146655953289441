import styled from "styled-components";

export const StyledMatchPlayerSummoner = styled.div`
  width: 16px;
  
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.loading {
    border: 1px solid ${({ theme }) => theme.color_rule};
  }
`;
