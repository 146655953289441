import React from "react";
import { useTranslation } from "react-i18next";
import { useMatchHistoryContext } from "../../../../context/matchHistory";
import { cls } from "../../../../helpers/utils";
import { useFetch } from "../../../../hooks/use-fetch";
import { MatchHistoryMatchTeamPlayer } from "../../../../services/championsQueueData/index.type";
import dataDragon from "../../../../services/dataDragon";
import InformError from "../../../Inform/Error";
import { StyledMatchPlayerAvatar } from "./style";

export type Props = {
  gameVersion: string
  player: MatchHistoryMatchTeamPlayer
};

const MatchPlayerAvatar: React.FC<Props> = ({ gameVersion, player }: Props) => {
  const { i18n } = useTranslation();
  const { highlightPlayerNames } = useMatchHistoryContext();

  const { isLoading, response, error } = useFetch(
    () => dataDragon.getChampionImage(
      i18n.language, gameVersion, player.championIcon
    ),
    [i18n.language, gameVersion, player.championIcon]
  );

  return (
    <StyledMatchPlayerAvatar
      className={cls(
        isLoading && "loading",
        highlightPlayerNames.filter((name) => name.includes(player.name)).length > 0 && "highlight",
      )}
    >
      {error && <InformError error={error} />}
      {response && <img src={response} alt={player.championIcon} />}
    </StyledMatchPlayerAvatar>
  );
};

export default MatchPlayerAvatar;
