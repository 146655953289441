import styled from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../Layout/theme";

export const StyledMatchDate = styled.div`
  color: ${({theme}) => theme.color_text_dimmed};
  font-size: 30px;

  .weekday {
    ${StyleFontHeader}
    letter-spacing: 0.05em;
  }

  .day-and-month {
    ${TextTransformUppercase}
    font-weight: 700;
  }

  @media ${device.large} {
    margin: ${({ theme }) => `${theme.gutter_size_large} ${theme.gutter_size_large} 0`};
  }

  @media ${device.medium} {
    margin: ${({ theme }) => `${theme.gutter_size_medium} ${theme.gutter_size_medium} 0`};
  }

  @media ${device.small} {
    margin: ${({ theme }) => `${theme.gutter_size_small} ${theme.gutter_size_small} 0`};
  }
`;
