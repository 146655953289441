import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MatchHistoryMatch } from "../../../services/championsQueueData/index.type";
import CompactMatchOverview from "../Overview/Compact";
import ExpandedMatchOverview from "../Overview/Expanded";
import { StyledMatchCard } from "./style";

export type Props = {
  match: MatchHistoryMatch
};

type CardStyle = "compact" | "expanded";

const MatchCard: React.FC<Props> = ({ match }: Props) => {
  const { t, i18n } = useTranslation();

  const [cardStyle, setCardStyle] = useState<CardStyle>("compact");

  const formatter = useMemo(
    () => new Intl.DateTimeFormat(i18n.language, {
      hour: "numeric",
      hour12: true
    }),
    []
  );

  const value = useMemo(
    () => formatter.format(Date.parse(match.matchStart)),
    [match.matchStart, i18n.language]
  );

  const handleClickExpand = () =>
    setCardStyle(cardStyle === "compact" ? "expanded" : "compact");

  return (
    <StyledMatchCard className={cardStyle}>
      <div className="match-card-time">
        {t("matchHistory:match.timeApprox", { value })}
      </div>
      <button className="expand-button" onClick={handleClickExpand}>
        {cardStyle === "compact" && "+"}
        {cardStyle === "expanded" && "-"}
      </button>
      {cardStyle === "compact" && <CompactMatchOverview match={match} />}
      {cardStyle === "expanded" && <ExpandedMatchOverview match={match} />}
    </StyledMatchCard>
  );
};

export default MatchCard;
