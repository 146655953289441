import styled from "styled-components";
import { device } from "../../Layout/theme";

export const StyledMatchSearch = styled.div`
  max-width: 270px;
  position: absolute;
  right: ${({ theme }) => theme.gutter_size_large};
  top: 0;

  @media ${device.small} {
    position: relative;
    right: auto;
    top: auto;
    max-width: 100%;
    margin: ${({ theme }) => theme.gutter_size_small};
    padding-top: 40px;
  }
`;
