import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useMatchHistoryContext } from "../../../../context/matchHistory";
import { cls } from "../../../../helpers/utils";
import { MatchHistoryMatch, MatchHistoryMatchTeam } from "../../../../services/championsQueueData/index.type";
import { renderPlayerName } from "../../../Champions/PlayerName";
import MatchPlayerAvatar from "../../Player/Avatar";
import MatchResultScore from "../../Result/Score";
import { StyledCompactMatchOverview } from "./style";

export type Props = {
  match: MatchHistoryMatch
};

const CompactMatchOverview: React.FC<Props> = ({ match }: Props) => {
  const { t } = useTranslation();
  const { highlightPlayerNames } = useMatchHistoryContext();

  const renderPlayerAvatars = (team: MatchHistoryMatchTeam) => (
    <div className="player-avatars">
      {team.players.map((player) => (
        <MatchPlayerAvatar
          key={player.name}
          gameVersion={match.gameVersion}
          player={player}
        />
      ))}
      {renderWinner(team)}
    </div>
  );

  const renderPlayerNames = (team: MatchHistoryMatchTeam) => (
    <div className="player-names">
      {team.players.map((player, i) => (
        <Fragment key={player.name}>
          <span
            className={cls(
              "player-name",
              highlightPlayerNames.filter((name) => name.includes(player.name)).length > 0 && "highlight",
            )}
          >
            {renderPlayerName(player.name)}
          </span>
          {i < team.players.length - 1 && <span className="divider">|</span>}
        </Fragment>
      ))}
    </div>
  );

  const renderWinner = (team: MatchHistoryMatchTeam) => team.winner && (
    <span className="winner">{t("matchHistory:match.winner")}</span>
  );

  return (
    <StyledCompactMatchOverview>
      <div className="team left">
        {renderPlayerAvatars(match.teams[0])}
        {renderPlayerNames(match.teams[0])}
      </div>
      <MatchResultScore match={match} />
      <div className="team right">
        {renderPlayerAvatars(match.teams[1])}
        {renderPlayerNames(match.teams[1])}
      </div>
    </StyledCompactMatchOverview>
  );
};

export default CompactMatchOverview;
