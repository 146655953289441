import React from "react";
import { cls } from "../../../../helpers/utils";
import { MatchHistoryMatch } from "../../../../services/championsQueueData/index.type";
import { StyledMatchResultScore } from "./style";

export type Props = {
  match: MatchHistoryMatch
};

const MatchResultScore: React.FC<Props> = ({ match }: Props) => {
  return (
    <StyledMatchResultScore>
      <span className={cls(match.teams[0].winner && "winner")}>
        {match.teams[0].score}
      </span>
      <span>{` - `}</span>
      <span className={cls(match.teams[1].winner && "winner")}>
        {match.teams[1].score}
      </span>
    </StyledMatchResultScore>
  );
};

export default MatchResultScore;
