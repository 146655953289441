import styled from "styled-components";
import { StyleFontHeader } from "../../../Layout/theme";

export const StyledMatchResultScore = styled.div`
  ${StyleFontHeader}
  font-size: 26px;
  flex-grow: 0;
  margin: 0 15px;
  white-space: nowrap;

  .winner {
    color: ${({ theme }) => theme.color_victory};
  }
`;
