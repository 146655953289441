import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SeeMoreChampions from "../components/Champions/Leaderboard/SeeMore";
import MatchHistoryFeature from "../components/Feature/MatchHistory";
import InformError from "../components/Inform/Error";
import InformLoading from "../components/Inform/Loading";
import Layout from "../components/Layout";
import { StyledLayoutContentArea } from "../components/Layout/style";
import MatchCard from "../components/Match/Card";
import MatchDate from "../components/Match/Date";
import MatchSearch from "../components/Match/Search";
import MatchHistoryContextProvider from "../context/matchHistory";
import { isSameDay } from "../helpers/utils";
import { useFetch } from "../hooks/use-fetch";
import { fetchMatchHistory } from "../services/championsQueueData";
import { MatchHistory, MatchHistoryMatch } from "../services/championsQueueData/index.type";
import placeholder from "../placeholders/matches.json";
import Fuse from "fuse.js";

const pageSize = 20;

const MatchHistoryPage: React.FC = () => {
  const { t } = useTranslation();

  const [pagesShown, setPagesShown] = useState<number>(1);

  const { isLoading, response, error } = useFetch(async () => {
    const response = await fetchMatchHistory();

    // TODO revert once the lambda is able to create matches.json
    if (response instanceof Error  || response.matches.length === 0) {
      return placeholder as MatchHistory;
    } else {
      return response;
    }
  }, []);

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const allMatches = useMemo(
    () => response?.matches?.sort(sortByMatchStartDateDesc) ?? [],
    [response]
  );
  const [filteredMatches, setFilteredMatches] = useState<MatchHistoryMatch[]>(allMatches);
  const matchesPaged = useMemo(
    () => filteredMatches.slice(0, pagesShown * pageSize),
    [filteredMatches, pagesShown]
  );
  const matchesPagedByDay = useMemo(
    () => groupByDay(matchesPaged),
    [matchesPaged]
  );

  const matchFuse = useMemo(
    () =>
      new Fuse(allMatches, {
        keys: ["teams.players.name"],
        threshold: 0.2,
        includeScore: true,
      }),
    [allMatches],
  );

  useEffect(() => {
    setPagesShown(1);
    if (currentQuery.trim().length === 0) {
      setFilteredMatches(allMatches);
    }
    else {
      setFilteredMatches(
        matchFuse.search(currentQuery).map((result) => result.item).sort(sortByMatchStartDateDesc)
      );
    }
  }, [allMatches, currentQuery]);

  const allPlayerNames = useMemo(
    () => Array.of(
      ...new Set(
        allMatches.flatMap(
          (match) => match.teams.flatMap(
            (team) => team.players.map((player) => player.name)
          )
        )
      )
    ),
    [allMatches]
  );

  return (
    <Layout>
      <GatsbySeo title={t("title")} />
      <MatchHistoryFeature />
      <StyledLayoutContentArea>
        {isLoading && <InformLoading />}
        {error && <InformError error={error} />}
        <MatchHistoryContextProvider>
          <MatchSearch allPlayerNames={allPlayerNames} onSearch={setCurrentQuery}/>
          {matchesPagedByDay.map((day, i) => (
            <StyledMatcHHistoryDay key={i}>
              <MatchDate time={Date.parse(day[0].matchStart)} />
              {day.map((match) => (
                <MatchCard key={match.matchStart} match={match} />
              ))}
            </StyledMatcHHistoryDay>
          ))}
          {matchesPaged.length < filteredMatches.length && (
            <SeeMoreChampions onClick={() => setPagesShown(pagesShown + 1)} />
          )}
        </MatchHistoryContextProvider>
      </StyledLayoutContentArea>
    </Layout>
  );
};

export default MatchHistoryPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;

const StyledMatcHHistoryDay = styled.div`
  &:nth-child(2) {
    margin-top: 80px;
  }

  &:last-child {
    margin-bottom: 80px;
  }
`;

const groupByDay = (matches: MatchHistoryMatch[]): MatchHistoryMatch[][] => {
  const days: MatchHistoryMatch[][] = [];
  let day: MatchHistoryMatch[] = [];
  let dayStart: number | undefined = undefined;

  matches.forEach((match) => {
    const matchStartDate = Date.parse(match.matchStart);

    if (dayStart === undefined) {
      day.push(match);
      dayStart = matchStartDate;
    }
    else if (isSameDay(dayStart, matchStartDate)) {
      day.push(match);
    }
    else {
      days.push(day);
      day = [match];
      dayStart = matchStartDate;
    }
  });

  if (day.length > 0) {
    days.push(day);
  }

  return days;
};

const sortByMatchStartDateDesc = (a: MatchHistoryMatch, b: MatchHistoryMatch) =>
  b.matchStart.localeCompare(a.matchStart);
