import { bcp2riot, bcpLocaleFromString } from "../../helpers/rioti18n";
import { fetch } from "../fetchUtils";

const baseUrl = "https://ddragon.leagueoflegends.com";

type DataDragonVersions = string[];

class DataDragon {
  private versions: DataDragonVersions | null = null;
  private versionsError: Error | null = null;
  private versionsFetch: Promise<DataDragonVersions | Error> | null = null;

  private fetchers: DataFetcher[] = [];

  async getChampionImage(
    locale: string,
    gameVersion: string,
    name: string
  ): Promise<string | Error> {
    const versions = await this.getOrInitVersions();

    if (versions instanceof Error) {
      return versions;
    }

    const ddLocale = this.dataDragonLocale(locale);
    const version = this.getMatchingVersion(versions, gameVersion);
    const fetcher = this.getOrCreateDataFetcher(ddLocale, version, "champion");
    const data = await fetcher.getItemData(
      (item) => item.name === name || item.id === name
    );

    if (data instanceof Error) {
      console.warn(`Unable to find Data Dragon champion image for ${name}`);
      return data;
    }

    return `${baseUrl}/cdn/${version}/img/champion/${data.image.full}`;
  }

  async getSummonerSpell(
    locale: string,
    gameVersion: string,
    key: number
  ): Promise<SummonerSpell | Error> {
    const versions = await this.getOrInitVersions();

    if (versions instanceof Error) {
      return versions;
    }

    const ddLocale = this.dataDragonLocale(locale);
    const version = this.getMatchingVersion(versions, gameVersion);
    const fetcher = this.getOrCreateDataFetcher(ddLocale, version, "summoner");
    const data = await fetcher.getItemData((item) => Number(item.key) === key);

    if (data instanceof Error) {
      console.warn(`Unable to find Data Dragon summoner spell image for ${key}`);
      return data;
    }

    return {
      imageUrl: `${baseUrl}/cdn/${version}/img/spell/${data.image.full}`,
      data
    };
  }

  private async getOrInitVersions(): Promise<DataDragonVersions | Error> {
    if (this.versions) {
      return this.versions;
    }

    if (this.versionsError) {
      return this.versionsError;
    }

    if (this.versionsFetch !== null) {
      return await this.versionsFetch;
    }

    this.versionsFetch = fetch(`${baseUrl}/api/versions.json`);
    const response = await this.versionsFetch;
    this.versions = response instanceof Error ? null : response;
    this.versionsError = response instanceof Error ? response : null;
    this.versionsFetch = null;
    return response;
  }

  private getMatchingVersion(versions: DataDragonVersions, gameVersion: string): string {
    if (!gameVersion) {
      return versions[0];
    }

    const normalizedVersion = this.normalizeGameVersion(gameVersion);
    return versions.find(
      (version) => version.startsWith(normalizedVersion)
    ) ?? versions[0];
  }

  private normalizeGameVersion (gameServerVersion: string): string {
    const [seasonNumber, patchNumber] = gameServerVersion.split(".");

    return seasonNumber + "." + patchNumber;
  }

  private dataDragonLocale(locale: string): string {
    return bcp2riot(bcpLocaleFromString(locale));
  }

  private getOrCreateDataFetcher(
    locale: string,
    version: string,
    type: DataDragonDataType
  ): DataFetcher {
    let fetcher = this.fetchers.find(
      (fetcher) =>
        fetcher.locale === locale
        && fetcher.version === version
        && fetcher.type === type
    );

    if (!fetcher) {
      fetcher = new DataFetcher(locale, version, type);
      this.fetchers.push(fetcher);
    }

    return fetcher;
  }
}

export default new DataDragon();

export type SummonerSpell = {
  imageUrl: string
  data: DataDragonItemData
};

export type DataDragonData = {
  data: Record<string, DataDragonItemData>
};

export type DataDragonItemData = {
  id: string // "MonkeyKing"
  key: string // "256"
  name: string // "Wukong"
  image: {
    full: string // "MonkeyKing.png"
  }
};

type DataDragonDataType = "champion" | "summoner";

class DataFetcher {
  locale: string;
  version: string;
  type: DataDragonDataType;

  private data: DataDragonData | null = null;
  private error: Error | null = null;
  private fetchPromise: Promise<DataDragonData | Error> | null = null;

  constructor(locale: string, version: string, type: DataDragonDataType) {
    this.locale = locale;
    this.version = version;
    this.type = type;
  }

  async getItemData(
    predicate: (item: DataDragonItemData) => boolean
  ): Promise<DataDragonItemData | Error> {
    const data = await this.getOrInitData();

    if (data instanceof Error) {
      return data;
    }

    const item = Object.values(data.data).find(predicate);

    return item ?? Error(`Cannot find champion with name ${name}`);
  }

  private async getOrInitData(): Promise<DataDragonData | Error> {
    if (this.data) {
      return this.data;
    }

    if (this.error) {
      return this.error;
    }

    if (this.fetchPromise !== null) {
      return await this.fetchPromise;
    }

    this.fetchPromise = fetch(
      `${baseUrl}/cdn/${this.version}/data/${this.locale}/${this.type}.json`
    );
    const response = await this.fetchPromise;
    this.data = response instanceof Error ? null : response;
    this.error = response instanceof Error ? response : null;
    this.fetchPromise = null;
    return response;
  }
}
