import styled, { css } from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../../Layout/theme";

const TableHeader = css`
  ${StyleFontHeader}
  font-size: 22px;
  color: ${({ theme }) => theme.color_text};
`;

export const StyledExpandedMatchOverview = styled.div`
  .score-container {
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .teams {
    display: flex;
    align-items: flex-start;
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;

    .team {
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .divider {
      position: absolute;
      top: 0;
      left: calc(50% - 1px);
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.color_rule};
    }
  }

  .row {
    display: flex;
    align-items: center;
    border: 1px solid transparent;

    &.left {
      margin-right: 10px;

      .summoner {
        margin-right: 20px;
      }
    }

    &.right {
      flex-direction: row-reverse;
      margin-left: 10px;

      .summoner {
        margin-left: 20px;
      }

      .name {
        text-align: right;
      }
    }

    &.highlight {
      background-color: ${({ theme }) => theme.color_rule};
      border-color: ${({ theme }) => theme.color_accent};
    }

    .avatar {
      width: 75px;
      flex-grow: 0;
    }

    .summoner {
      width: 20px;
      flex-grow: 0;
    }

    .name {
      flex-grow: 1;
      font-size: 19px;

      .winner {
        ${TextTransformUppercase}
        color: ${({ theme }) => theme.color_victory};
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.19em;
      }
    }

    .kda {
      width: 75px;
      flex-grow: 0;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${({ theme }) => theme.color_text_dimmed};

      &.header {
        ${TableHeader}
      }
    }

    .cs {
      width: 75px;
      flex-grow: 0;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${({ theme }) => theme.color_text_dimmed};

      &.header {
        ${TableHeader}
      }
    }

    .gold {
      width: 75px;
      flex-grow: 0;
      text-align: center;
      letter-spacing: 0.02em;
      color: ${({ theme }) => theme.color_text_dimmed};

      &.header {
        ${TableHeader}
      }
    }
  }

  @media ${device.large} {
    .row-narrow {
      display: none;
    }
  }

  @media ${device.small_and_medium} {
    .teams {
      padding: 0;
    }

    .row,
    .divider {
      display: none;
    }

    .row-narrow {
      display: block;

      &.left {
        border: 1px solid ${({ theme }) => theme.color_rule};
        border-left: none;
      }

      &.right {
        border: 1px solid ${({ theme }) => theme.color_rule};
        border-right: none;
        border-left: none;

        .name {
          text-align: right;
        }

        .info {
          flex-direction: row-reverse;

          .stats {
            text-align: right;
          }
        }
      }

      &.highlight {
        background-color: ${({ theme }) => theme.color_rule};
        border: 1px solid ${({ theme }) => theme.color_accent};
      }

      .name {
        padding: 5px 10px 0;
      }

      .info {
        display: flex;
        align-items: flex-start;

        .stats {
          color: ${({ theme }) => theme.color_text_dimmed};
          font-size: 14px;
        }
      }
    }
  }
`;
