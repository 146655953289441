import styled from "styled-components";
import { device, StyleFontHeader } from "../../Layout/theme";

export const StyledMatchCard = styled.div`
  background-color: ${({ theme }) => theme.color_background_tertiary};
  position: relative;
  overflow: auto;

  &.compact {
    height: 132px;
  }

  &.expanded {
    height: auto;
  }

  .match-card-time {
    ${StyleFontHeader}
    color: ${({theme}) => theme.color_text_dimmed};
    position: absolute;
    top: 10px;
    left: 20px;
    white-space: nowrap;
  }

  .expand-button {
    ${StyleFontHeader}
    color: ${({theme}) => theme.color_accent};
    font-size: 45px;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 132px;
    z-index: 100;

    &:focus {
      outline: none;
    }
  }

  @media ${device.large} {
    margin: 0 ${({ theme }) => theme.gutter_size_large} 5px;
  }

  @media ${device.small_and_medium} {
    padding: 40px 0 10px;

    &.compact {
      height: auto;
    }

    .expand-button {
      height: 60px;
    }
  }

  @media ${device.medium} {
    margin: 0 ${({ theme }) => theme.gutter_size_medium} 5px;
  }

  @media ${device.small} {
    margin: 0 ${({ theme }) => theme.gutter_size_small} 5px;
  }
`;
