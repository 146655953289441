import styled from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../../Layout/theme";

export const StyledCompactMatchOverview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .team {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    position: relative;

    .winner {
      ${TextTransformUppercase}
      color: ${({ theme }) => theme.color_victory};
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.19em;
      position: absolute;
      top: 50%;
    }

    &.left {
      align-items: flex-end;

      .winner {
        left: -20px;
        transform: translate(-100%, -50%);
      }
    }

    &.right {
      align-items: flex-start;

      .winner {
        right: -20px;
        transform: translate(100%, -50%);
      }
    }
  }

  .player-avatars {
    display: flex;
    position: relative;
  }

  .player-names {
    ${StyleFontHeader}
    color: ${({ theme }) => theme.color_text_dimmed};
    letter-spacing: 0.05em;
    font-size: 14px;
    display: flex;

    .player-name {
      padding: 0 7px;

      &.highlight {
        background-color: ${({ theme }) => theme.color_rule};
        border: 1px solid ${({ theme }) => theme.color_accent};
      }
    }

    .divider {
      margin: 0 2px;
    }
  }

  @media ${device.small_and_medium} {
    .team {
      .winner {
        display: none;
      }
    }

    .player-avatars {
      flex-wrap: wrap;
    }

    .player-names {
      flex-wrap: wrap;
    }
  }
`;
