import React from "react";
import { useTranslation } from "react-i18next";
import { useMatchHistoryContext } from "../../../../context/matchHistory";
import { cls } from "../../../../helpers/utils";
import {
  MatchHistoryMatch,
  MatchHistoryMatchTeam,
  MatchHistoryMatchTeamPlayer
} from "../../../../services/championsQueueData/index.type";
import { renderPlayerName } from "../../../Champions/PlayerName";
import MatchPlayerAvatar from "../../Player/Avatar";
import MatchPlayerSummoner from "../../Player/Summoner";
import MatchResultScore from "../../Result/Score";
import { StyledExpandedMatchOverview } from "./style";

export type Props = {
  match: MatchHistoryMatch
};

type Side = "left" | "right";

const ExpandedMatchOverview: React.FC<Props> = ({ match }: Props) => {
  const { t, i18n } = useTranslation();
  const { highlightPlayerNames } = useMatchHistoryContext();

  const renderKda = (player: MatchHistoryMatchTeamPlayer) =>
    t("matchHistory:match.kda", {
      kills: player.kills.toLocaleString(i18n.language),
      deaths: player.deaths.toLocaleString(i18n.language),
      assists: player.assists.toLocaleString(i18n.language)
    });

  const renderHeader = (side: Side, team: MatchHistoryMatchTeam) => (
    <div className={cls("row", side)}>
      <div className="avatar" />
      <div className="summoner" />
      <div className="name">
        {team.winner && (
          <span className="winner">{t("matchHistory:match.winner")}</span>
        )}
      </div>
      <div className="kda header">{t("matchHistory:match.headers.kda")}</div>
      <div className="cs header">{t("matchHistory:match.headers.cs")}</div>
      <div className="gold header">{t("matchHistory:match.headers.gold")}</div>
    </div>
  );

  const renderPlayerNarrow = (side: Side, player: MatchHistoryMatchTeamPlayer) => (
    <div
      key={player.name}
      className={cls(
        "row-narrow",
        side,
        highlightPlayerNames.filter((name) => name.includes(player.name)).length > 0 && "highlight",
      )}
    >
      <div className="name">{player.name}</div>
      <div className="info">
        <MatchPlayerAvatar gameVersion={match.gameVersion} player={player} />
        <div className="stats">
          <div>
            {t("matchHistory:match.labels.kda", { value: renderKda(player) })}
          </div>
          <div>
            {t("matchHistory:match.labels.cs", {
              value: player.cs.toLocaleString(i18n.language)
            })}
          </div>
          <div>
            {t("matchHistory:match.labels.gold", {
              value: player.gold.toLocaleString(i18n.language)
            })}
          </div>
        </div>
      </div>
    </div>
  );

  const renderPlayer = (side: Side, player: MatchHistoryMatchTeamPlayer) => (
    <div
      key={player.name}
      className={cls(
        "row",
        side,
        highlightPlayerNames.filter((name) => name.includes(player.name)).length > 0 && "highlight",
      )}
    >
      <div className="avatar">
        <MatchPlayerAvatar gameVersion={match.gameVersion} player={player} />
      </div>
      <div className="summoner">
        <MatchPlayerSummoner
          gameVersion={match.gameVersion}
          spellIconKey={player.spellIcon1}
        />
        <MatchPlayerSummoner
          gameVersion={match.gameVersion}
          spellIconKey={player.spellIcon2}
        />
      </div>
      <div className="name">{renderPlayerName(player.name)}</div>
      <div className="kda">{renderKda(player)}</div>
      <div className="cs">{player.cs.toLocaleString(i18n.language)}</div>
      <div className="gold">{player.gold.toLocaleString(i18n.language)}</div>
    </div>
  );

  const renderTeam = (side: Side, team: MatchHistoryMatchTeam) => (
    <div className="team">
      {renderHeader(side, team)}
      {team.players.map((player) => renderPlayer(side, player))}
      {team.players.map((player) => renderPlayerNarrow(side, player))}
    </div>
  );

  return (
    <StyledExpandedMatchOverview>
      <div className="score-container"><MatchResultScore match={match} /></div>
      <div className="teams">
        {renderTeam("left", match.teams[0])}
        <div className="divider"></div>
        {renderTeam("right", match.teams[1])}
      </div>
    </StyledExpandedMatchOverview>
  );
};

export default ExpandedMatchOverview;
