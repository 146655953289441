import styled from "styled-components";

export const StyledMatchPlayerAvatar = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 10px 8px;
  overflow: hidden;
  position: relative;
  border: 4px solid transparent;

  &.loading {
    border: 1px solid ${({ theme }) => theme.color_rule};
  }

  &.highlight {
    border-color: ${({ theme }) => theme.color_accent};
  }

  img {
    transform: scale(1.2);
  }
`;
