import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledMatchHistoryFeature } from "./style";

const MatchHistoryFeature: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledMatchHistoryFeature>
      <div className="title">
        <div className="line1">{t("matchHistory:feature.line1")}</div>
        &nbsp;
        <div className="line2">
          {t("matchHistory:feature.line2")}
          <StaticImage
            className="logo"
            src="../../../images/emea_icon.png"
            height={119}
            alt={t("nav.logo")}
          />
        </div>
      </div>
    </StyledMatchHistoryFeature>
  );
};

export default MatchHistoryFeature;
